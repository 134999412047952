<template>
  <div>
    <div data-test="content-enter-question">
      {{ meta.question }}
    </div>
    <div
      class="tt-black--text text--lighten-2"
      data-test="content-enter-type"
    >
      {{ callbackType }}
    </div>
  </div>
</template>

<script>
import { CallbackTypeMap } from '@/constants';

export default {
  name: 'ContentEnterString',
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  computed: {
    callbackType() {
      const callbackTypes = [];
      this.data.callback.forEach((item) => {
        callbackTypes.push(CallbackTypeMap[item.type] || item.type);
      });
      return callbackTypes.join(', ');
    },
    meta() {
      return JSON.parse(this.data.meta);
    },
  },
};
</script>

<style>
  /* stylelint-ignore no-empty-source */
</style>
